import React, { useState, useEffect } from "react";
import axios from "axios";

const LevelGet = () => {
  const [email, setEmail] = useState(""); // To store email
  const [levelDetails, setLevelDetails] = useState(null); // To store fetched level details

  useEffect(() => {
    if (email) {
      axios
        .get(`http://localhost:8080/api/v1/userlevel/admin-level/${email}`)
        .then((response) => {
          setLevelDetails(response.data.user.levelDetails);
        })
        .catch((error) => {
          console.error("Error fetching level details:", error);
        });
    }
  }, [email]); // Dependency array ensures the effect runs when email changes

  return (
    <div>
      <h2>Get User Level</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email"
      />
      {levelDetails ? (
        <div>
          <h3>Level Details:</h3>
          <p>Name: {levelDetails.name}</p>
          <p>Description: {levelDetails.description}</p>
          {/* You can add more level details here */}
        </div>
      ) : (
        <p>No level details found.</p>
      )}
    </div>
  );
};

export default LevelGet;
