import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../Assets/sitelogo.png";
import toast from "react-hot-toast";
import Layout from "./../../Componet/Layout/Layout";
import { useAuth } from "../../Context/auth";

import { FaWhatsapp } from "react-icons/fa";
import "../../App.css";

const ContactsUser = () => {
  const [auth] = useAuth();

  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [earnings, setEarnings] = useState(null);

  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  const getAllTitles = async () => {
    try {
      const { data } = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/contact/get-Contact"
      );
      if (Array.isArray(data.Contact)) {
        setTitles(data.Contact);
      } else {
        toast.error("Unexpected data format");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getAllTitles();
  }, []);

  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  return (
    <Layout>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          background: "radial-gradient(circle at top left, #1e1e2f, #121212)",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
        }}
      >
        {/* Background Shine Effect */}
        <div
          style={{
            position: "absolute",
            top: "-100px",
            left: "-100px",
            width: "300px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "50%",
            filter: "blur(100px)",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: "-100px",
            right: "-100px",
            width: "250px",
            height: "250px",
            background: "rgba(0, 255, 255, 0.1)",
            borderRadius: "50%",
            filter: "blur(80px)",
            zIndex: 1,
          }}
        ></div>

        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "#00ffff",
              marginBottom: "20px",
            }}
          >
            CONTACT
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong style={{ color: "#00ffff" }}>Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ color: "#00ffff" }}>Total Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1
          className="mt-5 mb-5 p-5"
          style={{ fontSize: "40px", textAlign: "center" }}
        >
          Contacts Us
        </h1>
        <div className="row justify-content-center">
          <div className="col-md-8">
            {titles.length === 0 ? (
              <p className="text-center"></p>
            ) : (
              titles.map((p) => (
                <div
                  key={p._id}
                  className="contact-card mb-4"
                  style={{ width: "400px" }}
                >
                  <div className="contact-header d-flex align-items-center">
                    <FaWhatsapp size={40} className="me-3" />
                    <h3 className="mb-0">{p.title}</h3>
                  </div>
                  <p className="contact-description">{p.Descriptions}</p>
                  {p.Link && (
                    <a
                      href={p.Link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="contact-link"
                    >
                      {p.title}
                    </a>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default ContactsUser;
