import React, { useState, useEffect } from "react";
import Layout from "./../../Componet/Layout/Layout";
import axios from "axios";
import { useAuth } from "../../Context/auth";
import logo from "../../Assets/sitelogo.png";
import {
  FaBlog,
  FaGamepad,
  FaHeadset,
  FaNotEqual,
  FaStore,
} from "react-icons/fa";

const UserAccount = () => {
  const [auth, setAuth] = useAuth();
  const [earnings, setEarnings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [levels, setLevels] = useState([]); // To store fetched levels
  const [userLevel, setUserLevel] = useState(null); // To store user's current level

  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/earnings"
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);

  // Dynamically set the color based on the verification status

  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };

  useEffect(() => {
    const fetchVerificationData = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/verify/user"
        ); // Adjust the API endpoint as per your backend
        const { verification } = response.data;

        if (verification) {
          // Set message and status based on the verification field
          if (verification.verification === "unverify") {
            setVerificationStatus("unverify");
            setVerificationMessage("unverify");
          } else if (verification.verification === "checking") {
            setVerificationStatus("checking");
            setVerificationMessage("checking");
          } else if (verification.verification === "verify") {
            setVerificationStatus("verify");
            setVerificationMessage("Verified");
          }
        } else {
          // If no verification data is found
          setVerificationStatus("unverify");
          setVerificationMessage("No verification data found");
        }
      } catch (error) {
        console.error("Error fetching verification data", error);
        setVerificationStatus("unverify");
        setVerificationMessage("Error occurred while checking verification");
      }
    };

    fetchVerificationData();
  }, []);

  // Dynamically set the background color based on verification status
  let statusColor = "red"; // Default color for unverified
  if (verificationStatus === "checking") {
    statusColor = "yellow";
  } else if (verificationStatus === "verify") {
    statusColor = "green";
  }

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        setError(null);

        // Fetch all levels from backend

        // Fetch current user's level
        const userResponse = await axios.get(
          "http://localhost:8080/api/v1/userlevel/user-level"
        );
        setUserLevel(userResponse.data.levelDetails); // Assuming the level details are returned here
      } catch (err) {
        console.error("Error fetching levels or user level:", err);
        setError("Unable to fetch levels. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchLevels();
  }, []);

  return (
    <Layout title={"User_Account  EᵃʳⁿTᵘᵇᵉ💲"}>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          background: "radial-gradient(circle at top left, #1e1e2f, #121212)",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
        }}
      >
        {/* Background Shine Effect */}
        <div
          style={{
            position: "absolute",
            top: "-100px",
            left: "-100px",
            width: "300px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "50%",
            filter: "blur(100px)",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: "-100px",
            right: "-100px",
            width: "250px",
            height: "250px",
            background: "rgba(0, 255, 255, 0.1)",
            borderRadius: "50%",
            filter: "blur(80px)",
            zIndex: 1,
          }}
        ></div>

        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "#00ffff",
              marginBottom: "20px",
            }}
          >
            Account
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong style={{ color: "#00ffff" }}>Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ color: "#00ffff" }}>Total Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* User Info Card */}
      <div
        style={{
          maxWidth: "400px",
          height: "120px",
          margin: "50px auto",
          padding: "20px",
          borderRadius: "15px",
          boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          background: "#f9f9f9",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Profile Icon (Clickable) */}
        <div
          style={{
            fontSize: "30px",
            backgroundColor: "#e0e0e0",
            borderRadius: "50%",
            padding: "10px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "/dashboard/user/profile"; // Navigate to profile page
          }}
        >
          👨🏻
        </div>

        {/* Username */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3
            style={{
              margin: "0",
              fontSize: "18px",
              color: "#333",
              fontWeight: "600",
            }}
          >
            {auth?.user?.username || "Username"}
          </h3>
        </div>

        {/* Verification Badge */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <span
            style={{
              fontSize: "12px",
              color: "#555",
              marginBottom: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              marginBottom: 20,
            }}
            onClick={() => {
              window.location.href = "/dashboard/user/userlevel"; // Navigate to level details
            }}
          >
            <strong>Your Level</strong>
            {/* Default level 0 */}
          </span>
          <span
            style={{
              color: "black",
              padding: "5px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: statusColor,
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => {
              window.location.href = "/dashboard/user/verification"; // Navigate to verification page
            }}
          >
            {verificationStatus}
          </span>
        </div>
      </div>

      {/* Photo Section */}
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <img
          src={logo} // Replace with actual photo
          alt="Section Image"
          style={{
            width: "100%",
            maxWidth: "300px",
            borderRadius: "10px",
            boxShadow: "0 3px 8px rgba(0,0,0,0.2)",
          }}
        />
      </div>

      {/* Cards Section */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr", // One column grid
          gap: "20px", // Space between cards
          maxWidth: "400px", // Center alignment width
          margin: "0 auto", // Centering in parent container
          padding: 10,
          marginBottom: 10,
        }}
      >
        {/* About Us Card */}
        <div
          style={{
            background: "white",
            color: "black",
            fontWeight: "bold",
            padding: "15px",
            borderRadius: "10px",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          }}
          onClick={() => (window.location.href = "")}
        >
          <FaStore />
          EarnTube Store
        </div>

        {/* Team Card */}
        <div
          style={{
            background: "white",
            color: "black",
            fontWeight: "bold",
            padding: "15px",
            borderRadius: "10px",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          }}
          onClick={() => (window.location.href = "")}
        >
          <FaNotEqual />
          Policy
        </div>

        {/* Policy Card */}
        <div
          style={{
            background: "white",
            color: "black",
            fontWeight: "bold",
            padding: "15px",
            borderRadius: "10px",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          }}
          onClick={() => (window.location.href = "/")}
        >
          <FaBlog />
          Blog
        </div>

        {/* Extra Section */}
        <div
          style={{
            background: "white",
            color: "black",
            fontWeight: "bold",
            padding: "15px",
            borderRadius: "10px",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          }}
          onClick={() =>
            (window.location.href = "/dashboard/user/verification")
          }
        >
          <FaHeadset />
          Verification Data
        </div>
      </div>
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default UserAccount;
