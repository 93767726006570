import React, { useEffect, useState } from "react";
import axios from "axios"; // Axios for API requests
import logo from "../../Assets/sitelogo.png";
import { useAuth } from "../../Context/auth";
import Layout from "./../../Componet/Layout/Layout";

function UserTasks() {
  const [auth] = useAuth();

  const [tasks, setTasks] = useState([]); // State to hold tasks
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [claimedTasks, setClaimedTasks] = useState([]); // State to track claimed tasks
  const [earnings, setEarnings] = useState(null);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  useEffect(() => {
    const fetchUserReferrals = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/total-referrals"
        );
        setTotalReferrals(response.data.totalReferrals);
        setReferrals(response.data.referralDetails);

        // Calculate active and inactive users
        const activeCount = response.data.referralDetails.filter(
          (referral) => referral.packageStatus === "Active"
        ).length;
        const inactiveCount =
          response.data.referralDetails.length - activeCount;

        setActiveUsers(activeCount);
        setInactiveUsers(inactiveCount);
      } catch (err) {
        setError("Failed to fetch referrals.");
      }
    };
    fetchUserReferrals();
  }, []);

  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/earnings"
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);

  // Function to fetch tasks from backend
  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/usertask/get" // Replace with your actual endpoint
      );

      setTasks(response.data); // Set the tasks in state
      setLoading(false); // Set loading to false after fetching
    } catch (err) {
      setError("Failed to fetch tasks"); // Set error message if fetching fails
      setLoading(false);
    }
  };

  // Function to claim a task
  const claimTask = async (taskId) => {
    try {
      const response = await axios.post(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/usertask/claim-task",
        { taskId }, // Send task ID in request body
        {}
      );

      // Update claimed tasks
      setClaimedTasks((prevClaimed) => [...prevClaimed, taskId]);

      alert("Task claimed successfully!");
    } catch (err) {
      console.error("Failed to claim task:", err.response?.data?.message);
      alert("Task already claimed Try next one.");
    }
  };

  useEffect(() => {
    fetchTasks(); // Fetch tasks when component mounts
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading while tasks are being fetched
  }

  if (error) {
    return <div>{error}</div>; // Show error if fetching fails
  }

  // Function to calculate active referrals for a user
  const getActiveReferralsCount = (task) => {
    const activeReferrals = referrals.filter(
      (referral) => referral.packageStatus === "Active"
    ).length;
    return activeReferrals >= task.referralRequirement
      ? `${task.referralRequirement}/${activeReferrals}`
      : `${task.referralRequirement}/${activeReferrals}`;
  };

  // Separate tasks based on type
  const sundayTasks = tasks.filter((task) => task.taskType === "sundayOffer");
  const monthlyTasks = tasks.filter((task) => task.taskType === "monthlyOffer");
  const referralTasks = tasks.filter((task) => task.taskType === "referral");
  const linkTasks = tasks.filter((task) => task.taskType === "link");
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  return (
    <Layout>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          background: "radial-gradient(circle at top left, #1e1e2f, #121212)",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
        }}
      >
        {/* Background Shine Effect */}
        <div
          style={{
            position: "absolute",
            top: "-100px",
            left: "-100px",
            width: "300px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "50%",
            filter: "blur(100px)",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: "-100px",
            right: "-100px",
            width: "250px",
            height: "250px",
            background: "rgba(0, 255, 255, 0.1)",
            borderRadius: "50%",
            filter: "blur(80px)",
            zIndex: 1,
          }}
        ></div>

        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "#00ffff",
              marginBottom: "20px",
            }}
          >
            TASKS
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong style={{ color: "#00ffff" }}>Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ color: "#00ffff" }}>Total Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Sunday Tasks */}
      <div className="card mb-5 mt-5">
        <h1 style={{ color: "black", fontWeight: "bold" }}>
          Sunday Super Offers
        </h1>
        {sundayTasks.length > 0 ? (
          <ul>
            {sundayTasks.map((task) => (
              <li key={task._id}>
                <h3 style={{ color: "black" }}>{task.reward}</h3>

                <p>
                  {task.referralRequirement
                    ? getActiveReferralsCount(task)
                    : null}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{ textAlign: "center" }}>
            This task only avaliable on weekend.
          </p>
        )}
      </div>

      {/* Monthly Tasks */}
      <div className="card mb-5 mt-5">
        <h1 style={{ color: "black", fontWeight: "bold" }}>
          Monthly Boom Offers
        </h1>
        {monthlyTasks.length > 0 ? (
          <ul>
            {monthlyTasks.map((task) => (
              <li key={task._id}>
                <h3 style={{ color: "black" }}>{task.reward}</h3>

                <p>
                  {task.referralRequirement
                    ? getActiveReferralsCount(task)
                    : null}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No Monthly tasks available.</p>
        )}
      </div>

      {/* Referral Requirement Tasks */}
      {/* Referral Requirement Tasks */}
      <div className="card mb-5 mt-5">
        <h1 style={{ color: "black", fontWeight: "bold" }}>Referral Tasks</h1>
        {referralTasks.length > 0 ? (
          <ul>
            {referralTasks.map((task, index) => {
              const activeReferrals = getActiveReferralsCount(task);
              const isButtonEnabled =
                activeUsers >= task.referralRequirement && !task.claimed;

              return (
                <li key={task._id}>
                  <h3 style={{ color: "black" }}>
                    <strong> {index + 1}.</strong> {task.reward}
                  </h3>
                  <p>
                    Price: {task.price} {displayCurrency}
                  </p>
                  <p>
                    Complete your active referral and get : {activeReferrals}
                  </p>

                  {/* Button logic */}
                  <button
                    onClick={() => claimTask(task._id)}
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: isButtonEnabled ? "green" : "gray",
                      color: "white",
                      cursor: isButtonEnabled ? "pointer" : "not-allowed",
                    }}
                    disabled={!isButtonEnabled} // Disable button if conditions are not met
                  >
                    {task.claimed ? "Claimed" : "Claim"}
                  </button>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No Referral Requirement tasks available.</p>
        )}
      </div>

      {/* Link Tasks */}
      <div className="card mb-5 mt-5">
        <h1 style={{ color: "black", fontWeight: "bold" }}>
          Follow Us On Social Media Tasks
        </h1>
        {linkTasks.length > 0 ? (
          <ul>
            {linkTasks.map((task) => (
              <li key={task._id}>
                <h3 style={{ color: "black" }}>{task.reward}</h3>

                <p>
                  Price: {task.price} {displayCurrency}
                </p>

                {/* Button to claim the task */}
                <button
                  onClick={() => {
                    // Open the link in a new tab
                    window.open(task.Links, "_blank");

                    // Show the Claim Reward button
                    setClaimedTasks((prev) => [...prev, task._id]); // Assuming you have a state to manage claimed tasks
                  }}
                >
                  Start
                </button>

                {/* Conditionally render the Claim Reward button */}
                {claimedTasks.includes(task._id) && (
                  <button
                    onClick={() => claimTask(task._id)} // Call your claimTask function
                    style={{ marginLeft: "10px", backgroundColor: "black" }}
                  >
                    Claim
                  </button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No Link tasks available.</p>
        )}
      </div>
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
}

export default UserTasks;
