import React, { useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import "../Styles/Home.css"; // Ensure this path is correct
import earn1 from "../Assets/earn1.webp";
import earn2 from "../Assets/earn2.png";
import earn3 from "../Assets/earn3.png";
import logo from "../Assets/sitelogo.png";

import { useAuth } from "../Context/auth";
import axios from "axios";
import Swiper from "swiper";
import ScrollReveal from "scrollreveal";

import toast from "react-hot-toast";

import Layout from "./../Componet/Layout/Layout";
import Spinner from "./../Componet/Spinner";

const Home = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(true);
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDescription, setSiteDescription] = useState("");

  useEffect(() => {
    const menuIcon = document.querySelector("#menu-icon");
    const navbar = document.querySelector(".navbar");
    const darkModeIcon = document.querySelector("#darkMode-icon");

    const handleMenuIconClick = () => {
      menuIcon.classList.toggle("bx-x");
      navbar.classList.toggle("active");
    };

    const handleDarkModeToggle = () => {
      darkModeIcon.classList.toggle("bx-sun");
      document.body.classList.toggle("dark-mode");
    };

    menuIcon?.addEventListener("click", handleMenuIconClick);
    darkModeIcon?.addEventListener("click", handleDarkModeToggle);

    const swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 50,
      loop: true,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    ScrollReveal({
      distance: "80px",
      duration: 2000,
      delay: 200,
    });

    ScrollReveal().reveal(".home-content, .heading", { origin: "top" });
    ScrollReveal().reveal(
      ".home-img img, .services-container, .portfolio-box, .testimonial-wrapper, .contact form",
      { origin: "bottom" }
    );
    ScrollReveal().reveal(".home-content h1, .about-img img", {
      origin: "left",
    });
    ScrollReveal().reveal(".home-content h3, .home-content p, .about-content", {
      origin: "right",
    });

    return () => {
      menuIcon?.removeEventListener("click", handleMenuIconClick);
      darkModeIcon?.removeEventListener("click", handleDarkModeToggle);
    };
  }, []);

  const getTitle = async () => {
    try {
      const { data } = await axios.get(
        "http://localhost:8080/api/v1/title/get-title"
      );
      setSiteTitle(data.titles[0]?.siteTitle || "Y-Ads");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTitle();
  }, []);
  const blogs = [
    {
      image: logo,
      title: "Welcome to EarnTube!",
      content:
        "EarnTube is more than just a platform—it's a gateway to unlocking new possibilities for earning money online, and it’s designed to cater to individuals with different financial goals. Whether you're looking for a quick boost to your income through short-term tasks or you want to build a steady, long-term stream of earnings, EarnTube offers a variety of flexible options to suit your needs. You can start earning by simply watching ads, which is a straightforward and enjoyable way to make money in your free time. What sets us apart is the diversity in earning options—while short-term tasks give you quick rewards, long-term packages help you set up a consistent flow of earnings that grow over time.At EarnTube, we believe in making the process not only simple but also engaging. The platform is designed to be user-friendly, making it easy for anyone to start earning right away, regardless of their experience with online platforms. It’s a fun, interactive way to make money from the comfort of your home. Moreover, we offer a variety of rewards, from the satisfaction of completing tasks to the excitement of winning through our Lucky Spin feature. With EarnTube, financial freedom is no longer a distant dream but an achievable goal, no matter how much time you can commit. It’s effective, convenient, and tailored to help you earn at your own pace.",
    },
    {
      image: earn1,
      title: "Short Earn vs Long Earn",
      content:
        "At EarnTube, we provide you with the flexibility to choose between two types of earning opportunities: Short-Term Tasks and Long-Term Packages, each designed to fit different financial needs and goals Short-Term Tasks are perfect for those who want to earn quick, immediate rewards. These tasks involve watching short ads or completing small activities that provide instant payouts. It’s an excellent choice if you’re looking for a quick way to make some extra cash in your spare time, whether it’s during a lunch break or while relaxing at home. These tasks are simple, fast, and offer immediate gratification, making them ideal for users who need quick earnings without a long-term commitment On the other hand, Long-Term Packages are designed for individuals who prefer a more strategic approach to earning money. With long-term earning options, you commit to a package that provides you with steady and consistent earnings over a longer period. This could include watching a series of ads or completing tasks that accumulate to build a reliable income stream over time. Long Earn is ideal for those who want to see sustainable growth in their earnings and are willing to invest a bit more time and effort to achieve long-term financial stability. This option is particularly beneficial for users who want to build a dependable source of income from EarnTube without having to constantly seek new opportunities With both options available, EarnTube allows you to choose the path that best suits your lifestyle and financial goals. Whether you’re looking for quick rewards or long-term earnings, there’s an opportunity for everyone to earn at their own pace.",
    },
    {
      image: earn2,
      title: "Lucky Spin - Spin Your Way to Rewards!",
      content:
        "Feeling lucky? EarnTube offers an exciting Lucky Spin feature that adds an extra layer of fun and excitement to your earning experience. By simply spinning the wheel, you have the chance to win a variety of thrilling rewards, such as bonus earnings, discounts on packages, and other fantastic surprises. This feature is not just about luck; it’s a fun and engaging way to enhance your earnings and make the process even more enjoyable. Whether you're a new user or a regular earner, the Lucky Spin gives you the opportunity to boost your income in a playful yet rewarding manner. It's the perfect way to maximize your earnings while enjoying the thrill of a game!",
    },
    {
      image: earn3,
      title: "Complete Daily Tasks & Earn More",
      content:
        "Stay active and maximize your earnings with EarnTube by completing daily tasks! Our system is designed to keep you engaged while you earn—whether it's watching videos, rating ads, or participating in other simple activities. These tasks are quick, easy, and perfect for users who want to earn consistently without a significant time commitment. By completing these daily tasks, you not only keep the earning momentum going but also unlock more opportunities to increase your rewards. The more active you are, the more you can earn, making it a fun and rewarding way to supplement your income every day!",
    },
  ];
  return (
    <Layout title={"Home Page"}>
      <>
        <section className="home" id="home" style={{ marginTop: "100px" }}>
          <div className="home-content">
            <h3>Welcome To</h3>
            <h1>
              {siteTitle ? (
                <NavLink to={"/"}>
                  <span style={{ color: "#00ffee" }}>{siteTitle}</span>
                </NavLink>
              ) : (
                ""
              )}
            </h1>
            <p>
              {siteDescription
                ? siteDescription
                : `Welcome to ${siteTitle}!.. Here you can earn unlimited money and make your future better. You can earn by watching ads, building a team, and getting a lot of bonuses along with a daily Sunday offer.
.`}
            </p>

            <div className="social-media">
              <a
                href="https://www.facebook.com/profile.php?id=61564420751716"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.instagram.com/earntube_official/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.youtube.com/@earntubeofficial1?sub_confirmation=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
            </div>
            {!auth.user ? (
              <div className="btn-group">
                <a className="btn" onClick={() => navigate("/login")}>
                  Get Start
                </a>
              </div>
            ) : (
              <div className="btn-group">
                <a
                  className="btn"
                  onClick={() =>
                    navigate(
                      `/dashboard/${auth.user.role === 1 ? "admin" : "user"}`
                    )
                  }
                >
                  Dashboard
                </a>
              </div>
            )}
          </div>
          <div
            className="profession-container"
            style={{ overflow: "hidden", marginTop: "100px" }}
          >
            <div className="profession-box">
              <div className="profession" style={{ "--i": 0 }}>
                <i className="bx bx-code-alt" />
                <h3>Watch Ads</h3>
              </div>

              <div className="profession" style={{ "--i": 1 }}>
                <i className="bx bx-camera" />
                <h3>Referral Commission</h3>
              </div>
              <div className="profession" style={{ "--i": 2 }}>
                <i className="bx bx-code" />
                <h3>Daily Sunday Offers</h3>
              </div>
              <div className="profession" style={{ "--i": 3 }}>
                <i className="bx bx-game" />
                <h3>Unlimited Bonus</h3>
              </div>
            </div>
            <div className="overlay" />
          </div>
          <div className="home-img">
            <img src="images/logo.png" alt="Logo" />
          </div>
        </section>

        <section className="about" id="about">
          <div className="container">
            <div className="about-section">
              {/* Left Side: Images */}

              {/* Right Side: Content */}
              <section className="about" id="about">
                <div className="container">
                  <h2 className="heading">
                    About <span>Us</span>
                  </h2>
                  <div className="about-section">
                    {/* Left Side: Images */}
                    <div className="images">
                      <img src={logo} alt="About Me 1" />
                      <img src={earn1} alt="About Me 2" />
                      <img src={earn2} alt="About Me 3" />
                      <img src={earn3} alt="About Me 4" />
                    </div>

                    {/* Right Side: Content */}
                    <div className="about-content">
                      <h3>
                        Welcome to{" "}
                        <span style={{ color: "#ff6347" }}>{siteTitle}</span>
                      </h3>
                      <p>
                        At <b>EᵃʳⁿTᵘᵇᵉ💲</b>, we believe in giving everyone an
                        opportunity to **earn money** from the comfort of their
                        home. Whether you're looking for <b>short-term gains</b>{" "}
                        or <b>long-term success</b>, we've got you covered with
                        various earning options.
                      </p>

                      <p>
                        Our platform offers{" "}
                        <b>secure and flexible income streams</b> designed for
                        all types of users. Here's what makes us special:
                      </p>

                      <ul>
                        <li>
                          <b style={{ color: "#ff6347" }}>
                            Short-Term Earnings:
                          </b>{" "}
                          Complete small tasks and watch your earnings grow
                          instantly.
                        </li>
                        <li>
                          <b style={{ color: "#ff6347" }}>
                            Long-Term Earnings:
                          </b>{" "}
                          Build consistent income through referrals and package
                          investments.
                        </li>
                        <li>
                          <b style={{ color: "#ff6347" }}>Auto Earnings:</b>{" "}
                          Earn while you're sleeping with automated systems.
                        </li>
                        <li>
                          <b style={{ color: "#ff6347" }}>
                            Task-Based Earnings:
                          </b>{" "}
                          Complete simple daily tasks to boost your income.
                        </li>
                        <li>
                          <b style={{ color: "#ff6347" }}>Lucky Spin:</b> Try
                          your luck and win exciting bonuses and prizes.
                        </li>
                      </ul>

                      <h4>Our Process</h4>
                      <p>Joining our platform is as simple as 1-2-3:</p>
                      <ol>
                        <li>Register for free and set up your profile.</li>
                        <li>Choose a package that suits your goals.</li>
                        <li>
                          Start earning through ads, referrals, and other
                          exciting opportunities.
                        </li>
                      </ol>

                      <p>
                        We have already helped{" "}
                        <b style={{ color: "#4caf50" }}>10,000+ users</b>{" "}
                        achieve their financial goals. Our platform is trusted
                        by users worldwide for its transparency and reliability.
                      </p>

                      <p>
                        <b style={{ color: "#ff6347" }}>Don't wait!</b> This is
                        your chance to build a bright future with minimal
                        investment. Let <b>EᵃʳⁿTᵘᵇᵉ💲</b> guide you on your
                        journey to success.
                      </p>

                      <a href="#" className="btn">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <section className="services" id="work">
          <div className="container">
            <h2 className="heading">
              Earn <span>By</span>
            </h2>

            {/* Card Section */}
            <div className="services-container">
              <div className="service-card">
                <img src={logo} alt="Short Earn" />
                <h3>Short Earn</h3>
                <div className="hover-details">
                  <h3>Short Earn</h3>
                  <p>
                    Short ads are quick and easy tasks you can complete daily to
                    earn money. Each package provides a different number of ads.
                  </p>
                </div>
              </div>

              <div className="service-card">
                <img src={earn1} alt="Long Earn" />
                <h3>Long Earn</h3>
                <div className="hover-details">
                  <h3>Long Earn</h3>
                  <p>
                    Long-term earning plans allow you to build sustainable
                    income over time through our innovative strategies.
                  </p>
                </div>
              </div>

              <div className="service-card">
                <img src={earn2} alt="Referral Commission" />
                <h3>Referral Commission</h3>
                <div className="hover-details">
                  <h3>Referral Commission</h3>
                  <p>
                    Share your referral code with friends and family. Earn
                    commissions whenever they join and purchase packages.
                  </p>
                </div>
              </div>

              <div className="service-card">
                <img src={earn3} alt="Bonus Commission" />
                <h3>Bonus Commission</h3>
                <div className="hover-details">
                  <h3>Bonus Commission</h3>
                  <p>
                    Active users are rewarded with special bonus commissions
                    based on their performance and activity.
                  </p>
                </div>
              </div>

              <div className="service-card">
                <img src={earn2} alt="Sunday Offer" />
                <h3>Sunday Offer</h3>
                <div className="hover-details">
                  <h3>Sunday Offer</h3>
                  <p>
                    Don’t miss out on our special Sunday offers where you can
                    maximize your earnings with exciting deals.
                  </p>
                </div>
              </div>
            </div>

            {/* Content + Images Section */}
            <div className="services-details">
              <div className="content">
                <h3>Why Choose Us?</h3>
                <p>
                  At <b>EᵃʳⁿTᵘᵇᵉ💲</b>, we provide flexible earning options for
                  everyone. Whether you're looking for quick earnings or
                  sustainable income, our platform has it all. Join our growing
                  community and take advantage of:
                </p>
                <ul>
                  <li>Daily short ads for quick income.</li>
                  <li>Long-term plans for consistent growth.</li>
                  <li>Exciting referral and bonus commissions.</li>
                  <li>Special Sunday offers and weekly promotions.</li>
                </ul>
              </div>
              <div className="images">
                <img src={logo} alt="Earning Opportunity 1" />
                <img src={earn2} alt="Earning Opportunity 2" />
              </div>
            </div>
          </div>
        </section>

        {/*blog*/}
        <section className="blog-section " id="blog">
          <h2 className="heading">
            About <span>EarnTube</span>
          </h2>
          {blogs.map((blog, index) => (
            <div
              key={index}
              className={`blog-container ${
                index % 2 === 0 ? "image-left" : "image-right"
              }`}
            >
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <div className="blog-content">
                <h3>{blog.title}</h3>
                <p>{blog.content}</p>
              </div>
            </div>
          ))}
        </section>

        {/* Testimole */}
        <section className="testimonials" id="testimonials">
          <h2 className="heading">
            User <span>Testimonials</span>
          </h2>
          <div className="testimonial-container">
            {/* Testimonial Card 1 */}
            <div className="testimonial-card">
              <img src={logo} alt="User 1" className="user-image" />
              <p className="review-text">
                "This platform has changed my life! I started with a small
                package, and now I am earning consistently. Highly recommend
                it!"
              </p>
              <div className="user-info">
                <h3>Zain</h3>
                <div className="stars">★★★★★</div>
              </div>
            </div>

            {/* Testimonial Card 2 */}
            <div className="testimonial-card">
              <img src={logo} alt="User 2" className="user-image" />
              <p className="review-text">
                "Amazing features and easy to use! The referral program is a
                game changer for me."
              </p>
              <div className="user-info">
                <h3>Farwa Ijaz</h3>
                <div className="stars">★★★★★</div>
              </div>
            </div>

            {/* Testimonial Card 3 */}
            <div className="testimonial-card">
              <img src={logo} alt="User 3" className="user-image" />
              <p className="review-text">
                "The Sunday offers are fantastic. It’s a reliable platform for
                earning extra money from home."
              </p>
              <div className="user-info">
                <h3>Ahmad Khan</h3>
                <div className="stars">★★★★</div>
              </div>
            </div>
            <div className="testimonial-card">
              <img src={logo} alt="User 3" className="user-image" />
              <p className="review-text">
                "I loved this platform (earntue) so much. Its the best side
                hustle for me. I'm making good amount frpm it and love it "
              </p>
              <div className="user-info">
                <h3>Abdul Mannan</h3>
                <div className="stars">★★★★</div>
              </div>
            </div>
          </div>

          {/* Additional Content */}
          <div className="additional-content">
            <p>
              Join thousands of satisfied users who are earning consistently
              from our platform. Get started today and experience the
              difference!
            </p>
          </div>
        </section>

        {/* contact section design */}
        <section className="contact" id="contact">
          <h2 className="heading">
            Contact <span>Me!</span>
          </h2>
          <form action="#">
            <div className="input-box">
              <input type="text" placeholder="Full Name" />
              <input type="email" placeholder="Email" />
            </div>
            <div className="input-box">
              <input type="number" placeholder="Mobile Number" />
              <input type="text" placeholder="Email Subject" />
            </div>
            <textarea name="" id="" cols="30" rows="10" placeholder="Message" />
            <input type="submit" value="Send Message" className="btn" />
          </form>
        </section>
      </>
    </Layout>
  );
};

export default Home;
