import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import AdminComponent from "../../Componet/Layout/AdminComponent";
import Spinner from "../../Componet/Spinner";

const UserVerification = () => {
  const [auth] = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [editData, setEditData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true); // Start spinner
      setError(null); // Clear previous error
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/verify/admin/all"
        );
        console.log("API Response Data:", response.data); // Debug response
        if (response.data && response.data.verifications) {
          const usersData = response.data.verifications.map((verification) => ({
            _id: verification._id,
            email: verification.userId?.email || "No Email",
            name: verification.Name || "No Name",
            cnic: verification.CNIC || "No CNIC",
            phoneNumber: verification.phoneNo || "No Phone",
            verificationStatus: verification.verification || "checking",
          }));
          setUsers(usersData);
        } else {
          throw new Error("Unexpected API response format");
        }
      } catch (err) {
        console.error("Error Fetching Users:", err); // Debug error
        setError("Error fetching users. Please try again later.");
      } finally {
        setLoading(false); // Stop spinner
      }
    };

    fetchUsers();
  }, []);

  const handleVerificationStatus = async (userId, newStatus) => {
    const validStatuses = ["verify", "unverify", "checking"];
    if (!validStatuses.includes(newStatus)) {
      setError("Invalid status value.");
      return;
    }

    try {
      const response = await axios.put(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/verify/admin/update/${userId}`,
        { status: newStatus }
      );
      console.log(response.data);
      const updatedUsers = users.map((user) =>
        user._id === userId ? { ...user, verificationStatus: newStatus } : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error(error);
      setError("Error updating verification status");
    }
  };

  const handleEdit = (user) => {
    setEditData(user);
    setModalVisible(true);
  };

  const handleDelete = async (userId) => {
    try {
      const response = await axios.delete(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/verify/admin/delete/${userId}`
      );
      console.log(response.data);
      setUsers(users.filter((user) => user._id !== userId));
    } catch (error) {
      console.error(error);
      setError("Error deleting user");
    }
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/verify/admin/edit/${editData._id}`,
        {
          CNIC: editData.CNIC,
          Name: editData.Name,
          phoneNo: editData.phoneNumber,
          verificationStatus: editData.verificationStatus, // Save verificationStatus
        }
      );
      console.log(response.data);
      const updatedUsers = users.map((user) =>
        user._id === editData._id ? { ...user, ...editData } : user
      );
      setUsers(updatedUsers);
      setModalVisible(false);
    } catch (error) {
      console.error(error);
      setError("Error updating verification data");
    }
  };

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="dashboard-container bg-light">
        <div className="right-content">
          <h1>User Verification</h1>
          <p>
            <span style={{ color: "#e0c10c", fontWeight: "bold" }}>
              👨{auth?.user?.username}
            </span>
            !
          </p>
        </div>
      </div>
      <AdminComponent />
      <div className="container-fluid m-3 p-3 w-100">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-8">
            <div className="w-100 p-3">
              <div className="w-100 p-3 bg-dark text-white mb-3 p-5 w-100">
                <h1>User Verification</h1>
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by email"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {loading ? (
                <Spinner />
              ) : error ? (
                <p>{error}</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-dark table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>CNIC</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user, index) => (
                        <tr key={user._id}>
                          <td>{index + 1}</td>
                          <td>{user.email}</td>
                          <td>{user.name}</td>
                          <td>{user.cnic}</td>
                          <td>{user.phoneNumber}</td>
                          <td>
                            <select
                              value={user.verificationStatus}
                              onChange={(e) =>
                                handleVerificationStatus(
                                  user._id,
                                  e.target.value
                                )
                              }
                            >
                              <option value="verify">Verify</option>
                              <option value="unverify">Unverify</option>
                              <option value="checking">Checking</option>
                            </select>
                          </td>
                          <td>
                            <button
                              onClick={() => handleEdit(user)}
                              className="btn btn-warning btn-sm m-1"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(user._id)}
                              className="btn btn-danger btn-sm m-1"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalVisible && editData && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Verification Data</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setModalVisible(false)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.Name}
                    onChange={(e) =>
                      setEditData({ ...editData, Name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>CNIC</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.CNIC}
                    onChange={(e) =>
                      setEditData({ ...editData, CNIC: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.phoneNumber}
                    onChange={(e) =>
                      setEditData({ ...editData, phoneNumber: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <select
                    value={editData.verificationStatus}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        verificationStatus: e.target.value,
                      })
                    }
                  >
                    <option value="verify">Verify</option>
                    <option value="unverify">Unverify</option>
                    <option value="checking">Checking</option>
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setModalVisible(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveEdit}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserVerification;
