import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import AdminComponent from "../../Componet/Layout/AdminComponent";

const AdminWithdrawal = () => {
  const [auth] = useAuth();
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleWithdrawals, setVisibleWithdrawals] = useState([]);
  const [searchEmail, setSearchEmail] = useState(""); // Search state

  useEffect(() => {
    const fetchWithdrawals = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/userwithdrawal/get-user-withdrawals"
        );

        if (response.data && response.data.withdrawals) {
          const sortedWithdrawals = response.data.withdrawals.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setWithdrawals(sortedWithdrawals);
          setVisibleWithdrawals(sortedWithdrawals);
        } else {
          setError("No withdrawals found");
        }
      } catch (error) {
        setError("Error fetching withdrawals");
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawals();
  }, []);

  const handleChangeStatus = async (withdrawalId, newStatus) => {
    try {
      const response = await axios.put(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/userwithdrawal/update-withdrawal-status/${withdrawalId}`,
        { status: newStatus }
      );

      const updatedWithdrawals = withdrawals.map((tx) =>
        tx._id === withdrawalId ? { ...tx, status: newStatus } : tx
      );
      setWithdrawals(updatedWithdrawals);
      setVisibleWithdrawals(
        updatedWithdrawals.filter((tx) => tx.status !== "complete")
      );
    } catch (error) {
      setError("Error updating transaction status");
    }
  };

  const handleDelete = async (withdrawalId) => {
    try {
      await axios.delete(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/userwithdrawal/delete-withdrawal/${withdrawalId}`
      );

      // Update the local state after deletion
      const updatedWithdrawals = withdrawals.filter(
        (tx) => tx._id !== withdrawalId
      );
      setWithdrawals(updatedWithdrawals);
      setVisibleWithdrawals(updatedWithdrawals);

      alert("Withdrawal deleted successfully!");
    } catch (error) {
      alert("Error deleting withdrawal!");
    }
  };

  const handleSearch = (e) => {
    setSearchEmail(e.target.value);
    if (e.target.value === "") {
      setVisibleWithdrawals(withdrawals);
    } else {
      const filtered = withdrawals.filter((tx) =>
        tx.userId?.email?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setVisibleWithdrawals(filtered);
    }
  };

  return (
    <>
      <div className="dashboard-container bg-light">
        <img
          src="images/spinnerlogo.jpeg"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          alt="Loading..."
        />
        <div className="right-content">
          <h1>All Transactions</h1>
          <p>
            <span style={{ color: "#e0c10c", fontWeight: "bold" }}>
              👨{auth?.user?.username}
            </span>
            !
          </p>
        </div>
      </div>

      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminComponent />
          </div>
          <div className="col-md-8">
            <div className="w-75 p-3 bg-dark text-white mb-3">
              <h1>All Withdrawals</h1>
              <input
                type="text"
                placeholder="Search by email"
                value={searchEmail}
                onChange={handleSearch}
                className="form-control"
              />
            </div>
            {error ? (
              <p>{error}</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-dark table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Amount</th>
                      <th>Payment Method</th>
                      <th>Account Number</th>
                      <th>Account Name</th>
                      <th>Applied Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visibleWithdrawals.length > 0 ? (
                      visibleWithdrawals.map((tx, index) => (
                        <tr key={tx._id}>
                          <td>{index + 1}</td>
                          <td>{tx.userId?.email ?? "N/A"}</td>
                          <td>{tx.amount} Rs</td>
                          <td>
                            {tx.paymentMethod ? tx.paymentMethod.method : "N/A"}
                          </td>
                          <td>{tx.accountNumber}</td>
                          <td>{tx.accountName}</td>
                          <td>{new Date(tx.createdAt).toLocaleDateString()}</td>
                          <td>
                            <select
                              value={tx.status}
                              onChange={(e) =>
                                handleChangeStatus(tx._id, e.target.value)
                              }
                            >
                              <option value="pending">Pending</option>
                              <option value="processing">Processing</option>
                              <option value="approved">Approved</option>
                              <option value="rejected">Rejected</option>
                            </select>
                          </td>
                          <td>
                            <button className="btn btn-success btn-sm">
                              Mark as Complete
                            </button>
                            <button
                              className="btn btn-danger btn-sm ml-2"
                              onClick={() => handleDelete(tx._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9">No withdrawals found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminWithdrawal;
