import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../Context/auth";
import { useNavigate } from "react-router-dom";
import "../../Styles/BuyPackage.css";
import toast from "react-hot-toast";
import Layout from "../../Componet/Layout/Layout";
import Spinner from "../../Componet/Spinner";
import logo from "../../Assets/sitelogo.png";

const BuyPackage = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [auth] = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [earnings, setEarnings] = useState(null);

  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  const userCurrency = auth?.user?.currency || "PKR"; // Default to PKR if no currency is set

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const getAllPackages = async () => {
    try {
      const { data } = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/package/get-package"
      );

      if (data.success) {
        let filteredPackages = [];

        if (data.packages && data.packages.length > 0) {
          if (userCurrency === "USD") {
            filteredPackages = data.packages.filter(
              (pkg) => pkg.currency === "USD"
            );
          } else {
            filteredPackages = data.packages.filter(
              (pkg) => pkg.currency === "PKR" || !pkg.currency
            );
          }

          if (filteredPackages.length === 0) {
            toast.error("No packages available for the selected currency.");
          } else {
            setPackages(filteredPackages);
          }
        } else {
          toast.error("No packages found.");
        }
      } else {
        toast.error(data.message || "Failed to fetch packages");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong fetching packages");
    }
  };

  const fetchUserEarnings = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/users/earnings"
      );
      setEarnings(response.data);
    } catch (err) {
      setError("Failed to fetch earnings.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPackages();
  }, [userCurrency]);

  useEffect(() => {
    fetchUserEarnings();
  }, []);
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  return (
    <Layout title="Buy Membership - Y-Ads">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div
            className="dashboard-container"
            style={{
              padding: "40px",
              borderRadius: "20px",
              background:
                "radial-gradient(circle at top left, #1e1e2f, #121212)",
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
              textAlign: "center",
              maxWidth: "750px",
              margin: "100px auto",
              position: "relative",
              overflow: "hidden",
              color: "white",
            }}
          >
            {/* Background Shine Effect */}
            <div
              style={{
                position: "absolute",
                top: "-100px",
                left: "-100px",
                width: "300px",
                height: "300px",
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "50%",
                filter: "blur(100px)",
                zIndex: 1,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                bottom: "-100px",
                right: "-100px",
                width: "250px",
                height: "250px",
                background: "rgba(0, 255, 255, 0.1)",
                borderRadius: "50%",
                filter: "blur(80px)",
                zIndex: 1,
              }}
            ></div>

            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 2 }}>
              <h1
                style={{
                  fontSize: "42px",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  color: "#00ffff",
                  marginBottom: "20px",
                }}
              >
                PLANS
              </h1>

              <div className="user-info" style={{ marginTop: "30px" }}>
                <img
                  src={logo}
                  alt="Dashboard Logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    border: "4px solid #00ffff",
                    boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
                  }}
                />

                <div
                  className="info-box"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "15px",
                    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <p
                    style={{
                      fontSize: "24px",
                      marginBottom: "10px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    👨 {auth?.user?.username}
                  </p>
                  <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                    <strong style={{ color: "#00ffff" }}>Earnings:</strong>{" "}
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <span style={{ color: "#00ffcc" }}>
                        {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                        {displayCurrency}
                      </span>
                    )}
                  </p>
                  <p style={{ fontSize: "18px" }}>
                    <strong style={{ color: "#00ffff" }}>
                      Total Earnings:
                    </strong>{" "}
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <span style={{ color: "#00ffcc" }}>
                        {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                        {displayCurrency}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="containerr p-4">
            <p className="alert alert-danger" role="alert">
              You can buy one package at a time. If any user buys any package
              until they have one active package, then the old one is
              deactivated, and the new one is active.
            </p>
            <h1
              className="text-center text-light bg-dark py-4 mb-5"
              style={{ fontSize: "40px" }}
            >
              Buy Package
            </h1>
            <div className="row justify-content-center">
              {packages.map((pkg) => (
                <div key={pkg._id} className="col-md-4 mb-4">
                  <div
                    className={`card rounded shadow text-center ${
                      !pkg.isActive ? "inactive-package" : ""
                    }`}
                  >
                    <div className="card-body">
                      <h5 className="card-title p-3 mb-3 bg-dark text-light">
                        {pkg.name}
                      </h5>
                      <div className="card-text p-3 mb-3">
                        {pkg.description.split(":").map((part, index) => (
                          <span key={index}>
                            {part.trim()}
                            <br />
                          </span>
                        ))}
                      </div>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Total Ads: {pkg.numOfAds}
                      </p>

                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Duration: {pkg.duration} days
                      </p>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Per Ads: {pkg.earningRate}{" "}
                        {earnings?.currency === "PKR"
                          ? "Rs"
                          : earnings?.currency === "USD"
                          ? "$"
                          : ""}
                      </p>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        AutoEarn income: 40%
                      </p>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Task Income: 20% to 80%
                      </p>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        LongEarn Income: ComingSoon
                      </p>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        LuckySpin Income: ComingSoon
                      </p>
                      <p
                        className="card-text p-3 mb-3"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Package Discount: {pkg.discount}
                      </p>

                      {!pkg.isActive && (
                        <div className="coming-soon-overlay">Coming Soon</div>
                      )}
                      <p
                        className="card-text p-3 mb-3"
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }} // Price styling change
                      >
                        Price: {pkg.price} {displayCurrency}
                      </p>
                      <button
                        className="btn"
                        style={{ width: "200px" }}
                        disabled={!pkg.isActive}
                        onClick={() =>
                          pkg.isActive &&
                          navigate(`/dashboard/user/payment-method/${pkg.slug}`)
                        }
                      >
                        {pkg.isActive ? "Buy Package" : "Package Inactive"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default BuyPackage;
