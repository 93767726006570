import React, { useState, useEffect } from "react";
import Layout from "./../../Componet/Layout/Layout";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import logo from "../../Assets/sitelogo.png";

import "../../Styles/Withdrawal.css";
import { FaYoutube } from "react-icons/fa";

const UserWithdrawal = () => {
  const [auth] = useAuth();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [titles, setTitles] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [amount, setAmount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [singleAccount, setSingleAccount] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [earnings, setEarnings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [minAmount] = useState();
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [verificationStatus, setVerificationStatus] = useState("unverify"); // Default "unverify"

  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);

  const fetchWithdrawalAccounts = async () => {
    try {
      const { data } = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/withdrawal/get-withdrawal-account"
      );
      setTitles(data.withdrawalAccounts);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while fetching the payment accounts");
    }
  };

  const fetchAccountDetail = async (id) => {
    try {
      const { data } = await axios.get(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/withdrawal/get-single-account/${id}`
      );
      setSingleAccount(data.singleAccount);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while fetching the package details");
    }
  };

  useEffect(() => {
    fetchWithdrawalAccounts();
  }, []);

  const handleMethodSelect = (methodId) => {
    setSelectedMethod(methodId);
    fetchAccountDetail(methodId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Package expiry check
    const packageExpiryDate = new Date(membershipDetails?.expiryDate);
    const currentDate = new Date();
    const daysRemaining = Math.ceil(
      (packageExpiryDate - currentDate) / (1000 * 60 * 60 * 24)
    );

    // Check if package is expiring within 30 days and if there is an active referral
    if (daysRemaining <= 25) {
      if (activeUsers < 1) {
        alert(
          "Please make at least 1 active refferal for whole month  withdrawal"
        );
        return; // Prevent withdrawal submission
      }
    }

    // Validation for other fields
    const errors = {};
    if (!amount.trim()) {
      errors.amount = "Amount is required";
    } else if (parseFloat(amount) < 1) {
      errors.amount = `Minimum amount is 0 ${displayCurrency}`;
    } else if (parseFloat(amount) > parseFloat(auth.user.availableEarnings)) {
      errors.amount = "Insufficient available funds";
    }
    if (!accountName.trim()) {
      errors.accountName = "Account Name is required";
    }
    if (!accountNumber.trim()) {
      errors.accountNumber = "Account Number is required";
    }
    if (!selectedMethod) {
      errors.selectedMethod = "Please select a payment method";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = {
        amount,
        paymentMethod: selectedMethod,
        accountNumber,
        accountName,
      };

      // Confirmation prompt before submitting withdrawal
      let answer = window.prompt(
        "Make sure your details are correct. Active package aur kam az kam 1 active referral required hai. Check karein aur 24 hours ka intezar karein. Enter any key to continue."
      );
      if (!answer) return;

      const { data } = await axios.post(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/userwithdrawal/create-withdrawal",
        formData
      );

      if (data.success) {
        toast.success(data.message);
        navigate("/dashboard/user/withdrawalhistory");
      } else {
        toast.error(data.message || "Failed to submit form");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while processing the payment");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fetch all Contacts
  const fetchsubscriberlink = async () => {
    try {
      const { data } = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/subscribe/get-link"
      );

      // Ensure data.links is an array of objects with a 'link' string property
      if (Array.isArray(data.links)) {
        setContacts(data.links);
      } else {
        toast.error("Unexpected data format");
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
      toast.error("Something went wrong");
    }
  };

  // Lifecycle method
  useEffect(() => {
    fetchsubscriberlink();
  }, []);
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  useEffect(() => {
    const fetchUserReferrals = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/total-referrals"
        );

        // Calculate active and inactive referrals
        const activeCount = response.data.referralDetails.filter(
          (referral) => referral.packageStatus === "Active"
        ).length;
        const inactiveCount =
          response.data.referralDetails.length - activeCount;

        setActiveUsers(activeCount);
        setInactiveUsers(inactiveCount);
      } catch (err) {
        setError("Failed to fetch referrals.");
      }
    };
    fetchUserReferrals();
  }, []);

  const checkVerificationStatus = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/verify/user"
      ); // Adjust the API endpoint as per your backend
      const { verification } = response.data;

      if (verification) {
        // Set message and status based on the verification field
        if (verification.verification === "unverify") {
          setVerificationStatus("unverify");
        } else if (verification.verification === "checking") {
          setVerificationStatus("checking");
        } else if (verification.verification === "verify") {
          setVerificationStatus("verify");
        }
      } else {
        // If no verification data is found
        setVerificationStatus("unverify");
      }
    } catch (error) {
      console.error("Error fetching verification data", error);
      setVerificationStatus("unverify");
    }
  };

  useEffect(() => {
    checkVerificationStatus();
  }, []); // Component load hone par status check ho jaayega
  //membership
  useEffect(() => {
    const fetchMembershipDetails = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/purchase/membership"
        );
        setMembershipDetails(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembershipDetails();
  }, []);
  return (
    <Layout>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          background: "radial-gradient(circle at top left, #1e1e2f, #121212)",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
        }}
      >
        {/* Background Shine Effect */}
        <div
          style={{
            position: "absolute",
            top: "-100px",
            left: "-100px",
            width: "300px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "50%",
            filter: "blur(100px)",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: "-100px",
            right: "-100px",
            width: "250px",
            height: "250px",
            background: "rgba(0, 255, 255, 0.1)",
            borderRadius: "50%",
            filter: "blur(80px)",
            zIndex: 1,
          }}
        ></div>

        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "#00ffff",
              marginBottom: "20px",
            }}
          >
            WITHDRAWALS
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong style={{ color: "#00ffff" }}>Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ color: "#00ffff" }}>Total Earnings:</strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "#00ffcc" }}>
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*  <div className="col-md-9">
        <div
          className="alert alert-warning"
          role="alert"
          style={{ padding: "20px", borderRadius: "10px", marginTop: "20px" }}
        >
          <p style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>
            Dear {auth.user.username} !! You need to subscribe this channal
            first, This is compulsory for your income.
            <div className="contact-list">
              {contacts.length === 0 ? (
                <p className="text-center">No Links Available</p>
              ) : (
                contacts.map((p) => (
                  <div key={p._id} className="col-md-4 mb-3">
                    <div className="contact-card">
                      <div className="d-flex align-items-center">
                        <FaYoutube size={24} className="mr-2 text-danger" />
                        <a
                          href={p.subscribechannallink}
                          className="link-secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontSize: "15px" }}
                        >
                          Click here
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </p>
        </div>
      </div>
*/}
      <div
        className="alert alert-success"
        role="alert"
        style={{ padding: "20px", borderRadius: "10px", marginTop: "20px" }}
      >
        <h2
          style={{ color: "black", textAlign: "center", marginBottom: "20px" }}
        >
          Withdrawal Rules
        </h2>
        <ol style={{ fontSize: "16px", lineHeight: "1.6" }}>
          <li>
            After applying for a withdrawal, you will receive it within 12 to 24
            hours.
          </li>
          <li>
            To receive a withdrawal, it is mandatory to subscribe to the channel
            first. Otherwise, your withdrawal will be rejected.
          </li>
          <li>
            After applying for a withdrawal, please wait up to 24 hours to
            receive it.
          </li>
          <li>
            Contact the withdrawal handler only in serious situations. If your
            withdrawal is delayed beyond 24 hours, then you may message the
            withdrawal handler.
          </li>
        </ol>
      </div>

      <div className="withdrawal-container">
        <h1 className="text-center mb-4">SELECT PAYMENT METHOD</h1>
        <div className="row justify-content-center">
          {titles.length === 0 ? (
            <p className="text-center"></p>
          ) : (
            <div className="col-md-8">
              <div className="d-flex flex-wrap justify-content-center">
                {titles.map((account) => (
                  <div
                    key={account._id}
                    className={`card bg-dark text-white m-2 p-3 ${
                      selectedMethod === account._id ? "border-primary" : ""
                    }`}
                    style={{ width: "200px", cursor: "pointer" }}
                    onClick={() => handleMethodSelect(account._id)}
                  >
                    <img
                      src={`https://earning-site-fll-backend-code.onrender.com/api/v1/withdrawal/account-photo/${account._id}`}
                      alt="Withdrawal Account"
                      className="card-img-top"
                      style={{ height: "100px", objectFit: "cover" }}
                    />
                  </div>
                ))}
              </div>

              {selectedMethod && (
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="form-group">
                    <label>Amount</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.amount ? "border-danger" : ""
                      }`}
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setErrors({ ...errors, amount: null });
                      }}
                    />
                    {errors.amount && (
                      <div className="text-danger">{errors.amount}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Account Name /Network</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.accountName ? "border-danger" : ""
                      }`}
                      placeholder="Account Name / TRX Tron (TRC20)"
                      value={accountName}
                      onChange={(e) => {
                        setAccountName(e.target.value);
                        setErrors({ ...errors, accountName: null });
                      }}
                    />
                    {errors.accountName && (
                      <div className="text-danger">{errors.accountName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Account Number/Wallet</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.accountNumber ? "border-danger" : ""
                      }`}
                      placeholder="123456789/TJsdCRJ1Kpk9w32SvS7fVqFerx6iz2fFP4"
                      value={accountNumber}
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                        setErrors({ ...errors, accountNumber: null });
                      }}
                    />
                    {errors.accountNumber && (
                      <div className="text-danger">{errors.accountNumber}</div>
                    )}
                  </div>
                  {/* <div className="text-center mt-4">
                    {activeUsers >= 1 ? (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit Withdrawal"}
                      </button>
                    ) : (
                      <button className="btn btn-primary" disabled>
                        Minimum 1 active referral required
                      </button>
                    )}
                  </div>*/}
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit Withdrawal"}
                  </button>
                </form>
              )}
            </div>
          )}
        </div>
        <div
          className="card mt-5 p-3"
          style={{
            width: "300px",
            background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
            borderRadius: "20px",
            boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
            color: "white",
            overflow: "hidden",
            position: "relative",
            textAlign: "center",
            transition:
              "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
            cursor: "pointer",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 15px 35px rgba(0, 0, 0, 0.3)";
            e.currentTarget.style.background =
              "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 10px 25px rgba(0, 0, 0, 0.2)";
            e.currentTarget.style.background =
              "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
          }}
        >
          {/* Glowing Effect */}

          {/* Main Content */}

          {/* Expandable Window Animation */}

          <div className="card mt-5 p-3" style={{ width: "300px" }}>
            <div className="bg-white">
              <label style={{ fontSize: "20px", textAlign: "center" }}>
                <strong>Expired</strong>
              </label>
              <p style={{ fontSize: "30px", color: "black" }}></p>
            </div>
          </div>
        </div>

        {singleAccount && (
          <div className="mt-4">
            <h3>Selected Account Details</h3>
            <p>Account Name: {singleAccount.accountName}</p>
            <p>Account Number: {singleAccount.accountNumber}</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UserWithdrawal;
